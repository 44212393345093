import router from '@/router'

function checkResponseStatus(responseData) {
  if(responseData.status == 403){
      return router.push({name: '403'})
    }
    else if(responseData.status == 404){
      return router.push({name: '404'})
    }
    else if(responseData.status == 401){
      return router.push({name: 'logout'})
    }

    return 1;
}

export {
  checkResponseStatus
}
