<template>
  <div>
    <b-card
      title = "Add New Role"
      class="border p-2"
    >

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form 
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >
          <b-row>

            <!-- Field: Full Name -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Role"
                rules="required"
              >
                <b-form-group
                  label="Role"
                  label-for="full-name"
                >
                  <b-form-input
                    id="full-name"
                    v-model="form.name"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>

                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- PERMISSION TABLE -->
          <b-card
            no-body
            class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="LockIcon"
                  size="18"
                />
                <span class="align-middle ml-50">Permission</span>
              </b-card-title>
            </b-card-header>

            <validation-provider
                #default="validationContext"
                name="permissionsData"
                rules="required"
              >
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="form.permissions"
                name="flavour-2"
                class="demo-inline-spacing"
                :state="getValidationState(validationContext)"
              >
                <b-table
                  striped
                  responsive
                  sticky-header
                  class="mb-0"
                  :items.sync="permissionsData"
                >
                  <template #cell(subject)="data">
                    {{ data.value }}
                  </template>
                  <template #cell()="data">
                    <b-form-checkbox v-if="data.value" :value="data.value" :checked="data.value" />
                  </template>
                </b-table>
              </b-form-checkbox-group>
            </validation-provider>

          </b-card>

          <!-- Action Buttons -->
          <b-row>

            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Save
              </b-button>
              <b-button
                variant="outline-secondary"
                :to="{name: 'role-list'}"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormCheckboxGroup, BFormInvalidFeedback
} from 'bootstrap-vue'

import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import roleStoreModule from '../roleStoreModule'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  methods: {
    onSubmit() {

      store.dispatch('app-role/addData', this.form)
        .then((response) => {
          if(response.data.status == 1){
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
            this.$router.push({name: 'role-list'})
          }
          else if(response.data.status == 0){
            if (response.data.message) {
              this.$swal({
                icon: 'error',
                title: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            
            }else{
              var errors = response.data.errors;
              this.$swal({
                  icon: 'error',
                  text: errors.join(', ' ),
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
            }
          }
      })
    }
  },
  setup(props) {
    var permissionsData = ref([])

    const ROLE_APP_STORE_MODULE_NAME = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })

    const getPermissionsData = async () => {
      store.dispatch('app-role/fetchPermissionOption')
        .then((response) => {
          if(response.data.status == 1)
            permissionsData.value = response.data.data
      })
    }

    onMounted(getPermissionsData)


    const form = {
      name: '',
      permissions: [],
    }

    const resetData = () => {
      form
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      form,
      permissionsData,
      getPermissionsData,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
