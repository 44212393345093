import axios from '@axios'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')

import {checkResponseStatus} from '../checkResponseStatus'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/roles', { params: queryParams })
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
              
          })
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/roles/${id}`)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
              
          })
          .catch(error => reject(error))
      })
    },
    addData(ctx, requestedData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/roles', requestedData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
              
          })
          .catch(error => reject(error))
      })
    },
    editData(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/roles/${requestData.id}`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPermissionOption(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/option/permission')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/role/${requestData.id}/change-archive-status`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
